import React from "react";
import { HashLink } from "react-router-hash-link";
import styles from "./ReadingBookComponent.module.scss";
import styled from "styled-components";

export default function ReadingBookComponent () {
	const urlImg = `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/web_site`;
	return (
		<>
			<h4>Hướng dẫn sử dụng các tính năng trong sách điện tử</h4>
			<ul class="guide-list">
				<li class="flex-lc">
					Hiện nay Hoc10 đã hỗ trợ nhiều tính năng trong sách điện tử để giúp thầy cô thuận tiện trong việc và giảng dạy. Trong bài viết này, Hoc10 xin được hướng dẫn thầy cô cách sử dụng
					các tính năng này một cách tốt nhất.
				</li>
				<ul className={ styles.tableMenu }>
					<h3>
						<p>Nội dung chính</p>
					</h3>
					<li>
						<HashLink
							to="#zoom-sach"
							scroll={ (el) =>
								el.scrollIntoView ({behavior : "auto", block : "center"})
							}
						>
							1. Zoom sách (Thu phóng màn hình)
						</HashLink>
					</li>
					<li>
						<HashLink
							to="#tim-den-trang-dich"
							scroll={ (el) =>
								el.scrollIntoView ({behavior : "auto", block : "center"})
							}
						>
							2. Tìm đến trang đích
						</HashLink>
					</li>
					<li>
						<HashLink
							to="#tra-cuu-noi-dung"
							scroll={ (el) =>
								el.scrollIntoView ({behavior : "auto", block : "center"})
							}
						>
							3. Tra cứu nội dung theo mục lục điện tử
						</HashLink>
					</li>
					<li>
						<HashLink
							to="#su-dung-thanh-cong-cu"
							scroll={ (el) =>
								el.scrollIntoView ({behavior : "auto", block : "center"})
							}
						>
							4. Sử dụng thanh công cụ
						</HashLink>
					</li>
					<li>
						<HashLink
							to="#xem-huong-dan"
							scroll={ (el) =>
								el.scrollIntoView ({behavior : "auto", block : "center"})
							}
						>
							5. Xem hướng dẫn
						</HashLink>
					</li>
					<li>
						<HashLink
							to="#tat-bat-icon"
							scroll={ (el) =>
								el.scrollIntoView ({behavior : "auto", block : "center"})
							}
						>
							6. Tắt/ bật icon sách
						</HashLink>
					</li>
					<li>
						<HashLink
							to="#xem-mot-trang-hai-trang"
							scroll={ (el) =>
								el.scrollIntoView ({behavior : "auto", block : "center"})
							}
						>
							7. Xem một trang/ hai trang
						</HashLink>
					</li>
				</ul>
				<li class="flex-lc">
					<FontBold id="zoom-sach">1.&nbsp; Zoom sách (Thu phóng màn hình)</FontBold>
					<p className="pl-3">
						Tính năng này giúp thầy cô dễ dàng phóng to hoặc thu nhỏ nội dung theo ý muốn. Đặc biệt, công cụ zoom trên sách điện tử của Hoc10 hỗ trợ phóng to theo vùng được chọn. Điều này
						có nghĩa là thay vì chỉ thu phóng từ giữa màn hình, thầy cô có thể zoom vào bất kỳ điểm cố định nào dựa trên vị trí con trỏ chuột.
					</p>
					<p className="mt-3">
                      Để phóng to sách, thầy cô có thể thực hiện theo một trong hai cách sau:
					</p>
					<p className="mt-3">
						<FontBold>Cách 1:</FontBold>&nbsp; Sử dụng con lăn chuột (nút giữa):
                      <p className="ml-5">
                        <ul>
                          <li>Lăn lên trên để phóng to nội dung.</li>
                          <li>Lăn xuống để thu nhỏ nội dung.</li>
                        </ul>
                      </p>
					</p>
					<p className="mt-3">
						<FontBold className="font-text-bold">Cách 2:</FontBold>&nbsp; Sử dụng biểu tượng trên màn hình:
                      <p className="ml-5">
                        <ul>
                          <li>Nhấn vào dấu "+" ở góc dưới bên phải màn hình để phóng to.</li>
                          <li>Nhấn vào dấu "-" để thu nhỏ.</li>
                        </ul>
                      </p>
                    </p>
					<div className="mt-3">
						<img src={ `${ urlImg }/HDSH_SACH.png` } alt="zoom_img"/>
					</div>
				</li>
				<li class="flex-lc">
					<FontBold id="tim-den-trang-dich">2.&nbsp; Tìm đến trang đích</FontBold>
					<p className="pl-3">
                      Công cụ này giúp thầy cô tìm nhanh một trang sách mà không cần phải lật từng trang. Để sử dụng, thầy cô thực hiện các bước sau:
					</p>
					<p className="mt-3">
						<FontBold>Bước 1:</FontBold>&nbsp; Nhấp vào ô hiển thị số trang ở phía dưới màn hình.
                    </p>
					<p className="mt-3">
						<FontBold>Bước 2:</FontBold>&nbsp; Nhập số trang mong muốn, sau đó nhấn Enter. Hệ thống sẽ tự động chuyển đến trang thầy cô cần tìm và sẵn sàng cho việc sử dụng.
                    
                    </p>
					<div className="mt-3">
						<img
							src={ `${ urlImg }/Tim_trang_dich.png` }
							alt="tim_trang_dich_img"
						/>
					</div>
				</li>
				<li class="flex-lc">
					<FontBold id="tra-cuu-noi-dung">3.&nbsp; Tra cứu nội dung bằng mục lục điện tử</FontBold>
					<p className="mt-3">
						<FontBold>Bước 1:</FontBold>&nbsp; Nhấn vào biểu tượng <FontBold>“Ba dấu gạch ngang”</FontBold> kèm chữ Mục lục ở bên trái màn hình.
					</p>
				
					<p className="mt-3">
						<FontBold>Bước 2:</FontBold>&nbsp; Thanh công cụ sẽ hiển thị ở phía bên trái màn hình, gồm các mục như: tên bài, chương, chủ đề, bảng giải thích từ ngữ, v.v.
					
					</p>
					<p className="mt-3">
						Thầy cô chỉ cần chọn nội dung mong muốn, hệ thống sẽ tự động chuyển đến phần tương ứng để sử dụng.
					</p>
					<div className="mt-3">
						<img src={ `${ urlImg }/tra_muc_luc.png` } alt="tra_muc_luc_img"/>
					</div>
				</li>
				<li class="flex-lc">
					<FontBold id="su-dung-thanh-cong-cu">4.&nbsp;  Sử dụng thanh công cụ</FontBold>
					<p className="mt-3">
						<FontBold>Bước 1:</FontBold>&nbsp;  Nhấn vào biểu tượng <FontBold>“chiếc bút” </FontBold> kèm chữ <FontBold>Công cụ</FontBold> ở bên trái màn
					</p>
					<p className="mt-3">
						<FontBold>Bước 2:</FontBold>&nbsp; Một thanh công cụ sẽ xuất hiện ở phía bên trái màn hình, cung cấp các tính năng như: bút vẽ, chèn văn bản, chèn liên kết, vẽ đoạn thẳng, đồng hồ bấm giờ, v.v.
					</p>
					<p className="mt-3">
						Những công cụ này giúp thầy cô thao tác trực tiếp và dễ dàng trên nội dung sách điện tử.
					</p>
					<p className="mt-3">
						Sau khi hoàn tất việc sử dụng thanh công cụ, thầy cô chỉ cần nhấn vào biểu tượng <FontBold>con trỏ chuột</FontBold> ở phía trên cùng để quay lại chế độ đọc sách và tiếp tục sử dụng.
					</p>
					<div className="mb-3">
						<img
							src={ `${ urlImg }/su-dung-thanh-cong-cu.png` }
							alt="thanh_cong_cu_img"
						/>
					</div>
				</li>
				<li class="flex-lc">
					<FontBold id="xem-huong-dan">5.&nbsp; Xem hướng dẫn</FontBold>
					<p className="mt-3">
						<FontBold>Bước 1:</FontBold>&nbsp;  Nhấn vào biểu tượng <FontBold>“dấu chấm hỏi”</FontBold> kèm chữ <FontBold>Hướng dẫn</FontBold> ở bên trái màn hình.
					</p>
					<p className="mt-3">
						<FontBold>Bước 2:</FontBold>&nbsp;Video hướng dẫn sẽ xuất hiện, hỗ trợ thầy cô theo dõi và thao tác trực quan, dễ dàng hơn.
					</p>
					<div className="mt-3">
						<img
							src={ `${ urlImg }/xem-huong-dan.png` }
							alt="huong_dan_su_dung_img"
						/>
					</div>
				</li>
				<li className="flex-lc">
					<FontBold id="tat-bat-icon">6.&nbsp; Tắt/ bật icon sách</FontBold>
					<p className="mt-3">
						Thầy cô có thể dễ dàng tắt hoặc bật icon sách khi không cần sử dụng bằng cách nhấn vào biểu tượng <FontBold>“Tắt/ Bật icon sách”</FontBold> trên giao diện màn hình.
					</p>
				</li>
				<li className="flex-lc">
					<FontBold id="xem-mot-trang-hai-trang">7.&nbsp; Xem một trang/ hai trang</FontBold>
					
					<p className="mt-3">
						Thầy cô có thể thay đổi chế độ hiển thị sách giữa <FontBold>1 trang</FontBold> hoặc <FontBold>2 trang</FontBold> bằng cách chọn chế độ tương ứng.
						<p className="ml-5">
							<ul>
								<li><FontBold>Xem một trang:</FontBold> Hiển thị một trang sách duy nhất.
								</li>
								<li><FontBold>Xem hai trang:</FontBold> Hiển thị hai trang sách cùng lúc.
								</li>
							</ul>
						</p>
					</p>
				</li>
				
				<li>
					Trường hợp cần hỗ trợ thêm, thầy cô vui lòng liên hệ tổng đài 024 7309
					8866 để được hỗ trợ nhé.
				</li>
				<li>
					Chúc thầy cô thao tác thành công và sử dụng sách một cách hiệu quả!
				</li>
			</ul>
		</>
	);
}

const FontBold = styled.b`
  font-family: SVN-GilroyBold, sans-serif;
`;
