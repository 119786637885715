import React from "react";

export default function DownloadLessonComponent() {
  const urlImg = `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/web_site`;
  return (
    <>
      <h4>Hướng dẫn sử dụng bài giảng</h4>
      <ul class="guide-list">
        <li class="flex-lc">
          <div className="mt-3 mb-3">
            <img
                src={`${urlImg}/6_1_2025_slide_1.PNG`}
                alt="Bấm chọn “Bài giảng” trên thanh công cụ đầu trang."
            />
          </div>
        </li>
        <li className="flex-lc">
          <div className="mt-3 mb-3">
            <img
                src={ `${ urlImg }/6_1_2025_slide_2.PNG` }
                alt="Bấm chọn “Bài giảng” trên thanh công cụ đầu trang."
            />
          </div>
        </li>
        <li className="flex-lc">
          <div className="mt-3 mb-3">
            <img
                src={ `${ urlImg }/6_1_2025_slide_3.PNG` }
                alt="Bấm chọn “Bài giảng” trên thanh công cụ đầu trang."
            />
          </div>
        </li>
        <li className="flex-lc">
          <div className="mt-3 mb-3">
            <img
                src={ `${ urlImg }/6_1_2025_slide_4.PNG` }
                alt="Bấm chọn “Bài giảng” trên thanh công cụ đầu trang."
            />
          </div>
        </li>
        <li className="flex-lc">
          <div className="mt-3 mb-3">
            <img
                src={ `${ urlImg }/6_1_2025_slide_5.PNG` }
                alt="Bấm chọn “Bài giảng” trên thanh công cụ đầu trang."
            />
          </div>
        </li>
      </ul>
    </>
  );
}
