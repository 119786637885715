import { useState } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import classNames from "classnames";
import BannerSlider from "./BannerSlider";
import FeedbackSlider from "./FeedbackSlider";
import { feelings } from "edu_lms_v2/data/feelings";
import { plans } from "edu_lms_v2/data/plans";
import * as PATH from "consts/routePaths";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";
import imgVideo from "../../assets/img/icon-video-2.svg";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";
import imageDefault from "edu_lms_v2/assets/img/hs1.png";
import imageDefault2 from "edu_lms_v2/assets/img/hs2.png";
import iconPlayVideo from "edu_lms_v2/assets/img/play_youtube.png";
import AlertNewExercise from "edu_lms_v2/components/AlertNewExercise";

export default function Home() {
  useScrollToTop();
  const [url, setUrl] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <main>
        <SEO data={pages.home} isHome={true} />
        <Slider className="hoc10-slider pr p-0">
          <BannerSlider />
        </Slider>
        <div className="hoc10-feature hoc10-feature--1">
          <div className="container">
            <div className="flex">
              <div className="feature">
                <h2 className="title">
                  <span className="line8">Học sinh</span> - Trải nghiệm học mới
                  mẻ và thú vị
                </h2>
                <ul className="feature__list">
                  <li>
                    <div className="icon flex-center">
                      <img src="/assets/img/icon-robot.svg" alt="Học bằng AI" />
                    </div>
                    <div className="text txt-1">
                      <div className="statistical d-inline bg-white">
                        <div className="text d-inline monkey-color-orange">
                          40 000+
                        </div>
                      </div>{" "}
                      bài tập tương tác chia theo từng bài học.
                    </div>
                  </li>
                  <li>
                    <div className="icon flex-center">
                      <img
                        src="/assets/img/icon-route.svg"
                        alt="Ôn luyện kiến thức"
                      />
                    </div>
                    <div className="text txt-1">
                      Làm bài miễn phí, trả kết quả tức thì.
                    </div>
                  </li>
                  <li>
                    <div className="icon flex-center">
                      <img
                        src="/assets/img/icon-chart.svg"
                        alt="Báo cáo học tập"
                      />
                    </div>
                    <div className="text txt-1">
                      Giao diện bắt mắt, đa dạng bài tập tương tác.
                    </div>
                  </li>
                  <li>
                    <div className="icon flex-center">
                      <img
                        src="/assets/img/icon-check.svg"
                        alt="Kiểm tra đánh giá năng lực"
                      />
                    </div>
                    <div className="text txt-1">
                      Lưu lịch sử làm bài, dễ dàng theo dõi tiến bộ.
                    </div>
                  </li>
                </ul>
                <Link
                  to={"doc-sach/tieng-viet-1-1/1/1/6/"}
                  title="Luyện tập ngay"
                  className="btn-pr flex-center"
                >
                  Luyện tập ngay
                </Link>
                {/* <span className="hello">Đang phát triển</span> */}
              </div>
              <div className="video img-wrapper position-relative">
                <img
                  src={imageDefault2}
                  alt="Trải nghiệm học mới mẻ và thú vị"
                />
                <div className="video__play cursor">
                  <img
                    onClick={() => {
                      setUrl("https://youtu.be/G7Di-wBBnTo");
                    }}
                    className="w-50"
                    src={iconPlayVideo}
                    alt="Chạy video"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hoc10-access pr">
          <div className="container pr">
            <div className="box-access bg-sh pr">
              <div className="content">
                <h3>
                  Truy cập ngay <span>Tủ sách diệu kỳ</span>
                </h3>
                <Link
                  to={PATH.ROUTE_PATH_TU_SACH}
                  title="Truy cập"
                  className="btn_access btn-pr flex-center"
                >
                  Truy cập ngay
                </Link>
              </div>
              <VideoPlayerModal
                show={Boolean(url)}
                url={url}
                onClose={() => setUrl("")}
              />
              <div className="video img-decor">
                <img src={imageDefault} alt="Truy cập ngay Tủ sách diệu kỳ" />
                <div
                  className="video__play cursor"
                  style={{ top: "45%", left: "55%" }}
                >
                  <img
                    onClick={() => {
                      setUrl("https://youtu.be/d2L8c06oU2Y");
                    }}
                    className="w-50"
                    src={iconPlayVideo}
                    alt="Chạy video"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="program">
          <div className="container">
            <div className="header">
              <h2 className="title">
                <span>Hoc10</span> Phát triển phẩm chất và năng lực học sinh
              </h2>
            </div>
            <div className="program__list flex content-intro">
              <div className="item">
                <div className="item__icon">
                  <img
                    src="/assets/img/book1.svg"
                    alt="Nội dung kiểm duyệt chặt chẽ"
                  />
                </div>
                <div className="item__content">
                  <h4 className="txt-2">
                    Nội dung thể hiện đúng và đầy đủ Chương trình giáo dục phổ
                    thông 2018
                  </h4>
                  <p>
                    Xây dựng bởi đội ngũ tác giả giàu kinh nghiệm về giáo dục
                    phổ thông, trong đó có nhiều chuyên gia tham gia xây dựng
                    Chương trình Giáo dục phổ thông 2018 của Bộ Giáo dục và Đào
                    tạo.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="item__icon">
                  <img
                    src="/assets/img/book2.svg"
                    alt="SGK điện tử tương tác"
                  />
                </div>
                <div className="item__content">
                  <h4 className="txt-2">SGK điện tử tương tác</h4>
                  <p>Tương tác trực tiếp tạo thuận lợi cho người sử dụng</p>
                </div>
              </div>
              <div className="item">
                <div className="item__icon">
                  <img src="/assets/img/book3.svg" alt="Kho bài tập đồ sộ" />
                </div>
                <div className="item__content">
                  <h4 className="txt-2">Kho bài tập tiện ích</h4>
                  <p>
                    Các bài tập tương tác phong phú, sinh động đi kèm lý thuyết
                    và lời giải chi tiết, hỗ trợ hiệu quả cho giáo viên, học
                    sinh và các bậc phụ huynh.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hoc10-plan">
          <div className="container">
            <div className="plan">
              <div className="header">
                <h2 className="title">
                  <span>Kế hoạch phát hành sách</span>
                  theo chương trình GDPT mới 2018
                </h2>
              </div>
              <ul className="timeline flex" plans={plans}>
                {plans.map(({ active, time, icon, grades }) => (
                  <li className={classNames({ active })}>
                    <div className="content">
                      <time>{time}</time>
                      <div className="icon flex-center">
                        <img src={icon} alt="Lớp học" />
                      </div>
                      <div className="class flex">
                        {grades.map((grade) => (
                          <span>{grade}</span>
                        ))}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="statistical flex">
                <div className="item flex-lc">
                  <div className="icon">
                    <img src="/assets/img/icon-edu.svg" alt="Giáo viên" />
                  </div>
                  <div className="content">
                    <div className="number">390.000+</div>
                    <div className="text">Giáo viên</div>
                  </div>
                </div>
                <div className="item flex-lc">
                  <div className="icon">
                    <img src="/assets/img/icon-school.svg" alt="Trường học" />
                  </div>
                  <div className="content">
                    <div className="number">35.000+</div>
                    <div className="text">Trường học</div>
                  </div>
                </div>
                <div className="item flex-lc">
                  <div className="icon">
                    <img src="/assets/img/icon-book1.svg" alt="Đầu sách" />
                  </div>
                  <div className="content">
                    <div className="number">500+</div>
                    <div className="text">Đầu sách</div>
                  </div>
                </div>
                <div className="item flex-lc">
                  <div className="icon">
                    <img
                      src="/assets/img/icon-book2.svg"
                      alt="Bài tập tương tác"
                    />
                  </div>
                  <div className="content">
                    <div className="number">20.000+</div>
                    <div className="text">Bài tập tương tác</div>
                  </div>
                </div>
                <div className="item flex-lc">
                  <div className="icon">
                    <img src={imgVideo} alt="Video minh họa" />
                  </div>
                  <div className="content">
                    <div className="number">1.400+</div>
                    <div className="text">Video minh họa</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hoc10-feature hoc10-feature--2">
          <div className="container">
            <div className="flex">
              <div className="img-wrapper">
                <img
                  src="/assets/img/img-dc3.png"
                  alt="Công cụ hữu ích và thuận tiện mới"
                />
              </div>
              <div className="feature">
                <h2 className="title">
                  <span className="line8">Giáo viên</span> - Công cụ hữu ích và
                  thuận tiện mới
                </h2>
                <ul className="feature__list">
                  <li>
                    <div className="icon flex-center">
                      <img
                        src="/assets/img/icon-laptop.svg"
                        alt="Giảng dạy trực tuyến"
                      />
                    </div>
                    <div className="text txt-1">
                      Giảng dạy trực tuyến dễ dàng
                    </div>
                  </li>
                  <li>
                    <div className="icon flex-center">
                      <img
                        src="/assets/img/icon-manager.svg"
                        alt="Quản lí lớp học"
                      />
                    </div>
                    <div className="text txt-1">Quản lí lớp học thuận tiện</div>
                  </li>
                  <li>
                    <div className="icon flex-center">
                      <img
                        src="/assets/img/icon-media.svg"
                        alt="Soạn bài giảng trực tuyến"
                      />
                    </div>
                    <div className="text txt-1">
                      Soạn bài giảng trực tuyến nhanh chóng
                    </div>
                  </li>
                  <li>
                    <div className="icon flex-center">
                      <img
                        src="/assets/img/icon-check.svg"
                        alt="Kiểm tra đánh giá năng lực"
                      />
                    </div>
                    <div className="text txt-1">
                      Kiểm tra, đánh giá năng lực chính xác
                    </div>
                  </li>
                </ul>
                <Link
                  to={PATH.ROUTE_PATH_V2_TEACHER}
                  title="Đang phát triển"
                  className="btn-pr flex-center disabled-link"
                >
                  Đang phát triển
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hoc10-register pr">
          <div className="container pr">
            <div className="content">
              <h3 className="txt-3">
                Trải nghiệm đầy đủ <br /> tính năng Hoc10
              </h3>
              <Link
                to={PATH.ROUTE_PATH_V3_REGISTER}
                title="Đăng ký ngay"
                className="btn_register flex-center"
              >
                Đăng ký ngay
              </Link>
            </div>
            <div className="img-wrapper sm bg-sh">
              <img
                src="/assets/img/img-tn1.jpg"
                alt="Trải nghiệm đầy đủ tính năng học 10"
              />
            </div>
          </div>
          <div className="img-wrapper lg bg-sh">
            <img
              src="/assets/img/img-tn2.jpg"
              alt="Trải nghiệm đầy đủ tính năng học 10"
            />
          </div>
        </div>
        {/* <div className="hoc10-feedback">
          <div className="container">
            <div className="header">
              <h2 className="title">
                <span className="inline">Cảm nghĩ</span> người dùng về Hoc10
              </h2>
            </div>
            <div className="feedback-list">
              <FeedbackSlider feelings={feelings} />
            </div>
          </div>
        </div> */}
        {/* <div className="hoc10-posts">
          <div className="container">
            <div className="header flex-lc">
              <h2 className="title">
                <span>Bài viết</span> - Chia sẻ đến từ Hoc10
              </h2>
              <a
                href=""
                title="Xem tất cả"
                className="btn_viewmore btn-pr flex-center"
              >
                Xem tất cả
              </a>
            </div>
            <div className="post-list row pr">
              <div className="col-lg-4 col-md-6">
                <article className="post post-ty1">
                  <a href="" className="post__thumb">
                    <img src="/assets/img/img-new1.jpg" className="fit" alt="" />
                  </a>
                  <div className="flex-lc">
                    <div className="category">HỌC TẬP</div>
                    <div className="space"></div>
                    <time>12 tháng 10, 2021</time>
                  </div>
                  <h3>
                    <a href="" title="" className="txt-2">
                      Công nghệ AI khiến trải nghiệm học tập trở nên thú hơn bao
                      giờ hết
                    </a>
                  </h3>
                  <p>
                    Xây dựng bởi đội ngũ tác giả đầu ngành - quy tụ hầu hết chuyên
                    gia phát triển chương trình ...
                  </p>
                </article>
              </div>
              <div className="col-lg-4 col-md-6">
                <article className="post post-ty1">
                  <a href="" className="post__thumb">
                    <img src="/assets/img/img-new2.jpg" className="fit" alt="" />
                  </a>
                  <div className="flex-lc">
                    <div className="category">HỌC TẬP</div>
                    <div className="space"></div>
                    <time>12 tháng 10, 2021</time>
                  </div>
                  <h3>
                    <a href="" title="" className="txt-2">
                      Trăn trở việc hỗ trợ con cái học online của phụ huỳnh mùa
                      dịch
                    </a>
                  </h3>
                  <p>
                    Xây dựng bởi đội ngũ tác giả đầu ngành - quy tụ hầu hết chuyên
                    gia phát triển chương trình ...
                  </p>
                </article>
              </div>
              <div className="col-lg-4 col-md-6">
                <article className="post post-ty1">
                  <a href="" className="post__thumb">
                    <img src="/assets/img/img-new3.jpg" className="fit" alt="" />
                  </a>
                  <div className="flex-lc">
                    <div className="category">HỌC TẬP</div>
                    <div className="space"></div>
                    <time>12 tháng 10, 2021</time>
                  </div>
                  <h3>
                    <a href="" title="" className="txt-2">
                      Học sinh hào hứng với trải nghiệm học tập online được cải
                      tiến
                    </a>
                  </h3>
                  <p>
                    Xây dựng bởi đội ngũ tác giả đầu ngành - quy tụ hầu hết chuyên
                    gia phát triển chương trình ...
                  </p>
                </article>
              </div>
            </div>
            <a
              href=""
              title="Xem tất cả"
              className="btn_viewall flex-center btn-pr"
            >
              Xem tất cả
            </a>
          </div>
        </div> */}
      </main>
      {showPopup && (
        <AlertNewExercise show={showPopup} onHide={() => setShowPopup(false)} />
      )}
    </>
  );
}

const Slider = styled.div`
  background: content-box !important;
  padding-top: 15px !important;
  .slick-dots {
    margin-top: 25px;
  }
  .slick-prev {
    left: -23px;
    border: 1px solid #ff7707;
  }
  .slick-next {
    right: -23px;
    border: 1px solid #ff7707;
  }
  .slick-arrow {
    top: 45%;
  }
  .slick-prev:hover,
  .slick-next:hover {
    box-shadow: 0 0 40px #ff7707;
  }
  @media screen and (max-width: 576px) {
    .slick-arrow {
      width: 30px;
      height: 30px;
    }
    .slick-prev {
      left: -15px;
    }
    .slick-next {
      right: -15px;
    }
  }
`;
