import classNames from "classnames";
import styled from "styled-components";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useStateMachine } from "little-state-machine";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateState, resetState } from "./store";
import styles from "./Register.module.css";

import {
  APP_ID,
  FACEBOOK_TYPE,
  GMAIL_TYPE,
  JOB_STUDENT,
} from "../../../edu_lms/constants/type";
import { onListGradeSubject } from "edu_lms/modules/UpdateInformation/config";
import { onAddLocalStorage, setItemUserInfo } from "edu_lms/modules/selection";
import { postLogin } from "edu_lms/services/signIn/signIn";
import LoginSuccessModal from "../Login/LoginSuccessModal";
import RegisterSuccessModal from "./RegisterSuccessModal";
import { postVerifyEmail, postVerifyPhone } from "edu_lms/services/info/info";
import {
  TEXT_VERIFY_EMAIL_ERROR,
  TEXT_VERIFY_PHONE_ERROR,
} from "edu_lms/constants/text";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import {
  OB_ACCOUNT_TYPE,
  OB_PERSONAL_INFO,
} from "edu_lms/constants/eventNameGTM";

const phoneRegExp = /([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/;

const schema = yup.object().shape({
  full_name: yup.string().required("Họ và tên bắt buộc nhập"),
  phone: yup
    .string()
    .required("SĐT là bắt buộc nhập")
    .matches(phoneRegExp, "SĐT không hợp lệ"),
  email: yup
    .string()
    .email("Email không hợp lệ. Vui lòng nhập đúng định dạng (ví dụ: yourname@example.com).")
    .required("Email là bắt buộc nhập"),
  password: yup
    .string()
    .required("Mật khẩu là bắt buộc nhập")
    .min(8, "Mật khẩu phải có ít nhất 8 kí tự"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Mật khẩu không khớp!")
    .required("Trường này là bắt buộc nhập"),
});

export default function Step2({
  goToPrevStep,
  goToNextStep,
  onComplete,
  show,
  totalStep,
  currentStep,
  checkJob,
  isOpenTraining,
}) {
  const { state, actions } = useStateMachine({ updateState, resetState });
  const [showModalLoginSuccess, setshowModalLoginSuccess] = useState(false);
  const [isPassword, setStatePassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      full_name: state.full_name,
      phone: state.phone,
      email: state.email,
      password: state.password,
      confirmPassword: state.confirmPassword,
    },
  });

  const responseFace = (data) => {
    data = {
      type: FACEBOOK_TYPE,
      email: data.email ?? data.email,
      name: data.name ?? data.name,
      access_token: data.accessToken,
      is_web: 1,
      app_id: APP_ID,
    };
    onPostLogin(data);
  };
  const responseGoogle = (data) => {
    data = {
      type: GMAIL_TYPE,
      email: data.email ?? data.profileObj.email,
      name: data.name ?? data.profileObj.name,
      access_token: data.accessToken,
      is_web: 1,
      isGoogle: true,
    };
    onPostLogin(data);

    setEventGTM({
      event: OB_PERSONAL_INFO,
      type: "google",
      successful: true,
      failed_reason: "",
    });
  };
  const onPostLogin = (data) => {
    postLogin(data).then((response) => {
      if (response.data.status === "success") {
        setshowModalLoginSuccess(true);
        const listGradeSubject = onListGradeSubject(
          response.data.data.user_info.list_grade_subject
        );
        const userInfo = {
          ...response.data.data.user_info,
          list_grade_subject: listGradeSubject,
        };
        setItemUserInfo(userInfo);
        onAddLocalStorage(
          response.data.data.access_token,
          response.data.data.user_id,
          1
        );
      } else {
        toast.error(response.data.message || "Lỗi đăng nhập");
      }
    });
  };

  const onSubmit = async (data) => {
    let errorMessage = "";
    const paramVerifyPhone = {
      phone: data.phone,
      app_id: APP_ID,
      is_web: 1,
    };
    const paramVerifyEmail = {
      email: data.email,
    };
    data.phone = data.phone.trim();
    actions.updateState(data);
    const verifyPhone = await postVerifyPhone(paramVerifyPhone);
    if (data.email) {
      const verifyEmail = await postVerifyEmail(paramVerifyEmail);
      if (!verifyEmail.data.data.is_new_register) {
        errorMessage = TEXT_VERIFY_EMAIL_ERROR;
      }
    }

    if (!verifyPhone.data.data.is_new_register)
      errorMessage = TEXT_VERIFY_PHONE_ERROR;

    if (errorMessage) {
      setEventGTM({
        event: OB_PERSONAL_INFO,
        type: "form",
        successful: false,
        failed_reason: errorMessage,
      });
      return toast.error(errorMessage);
    }

    if (currentStep === 2 && Number(state.job_id) !== JOB_STUDENT) {
      if (verifyPhone.data.data.is_new_register) {
        setEventGTM({
          event: OB_ACCOUNT_TYPE,
          type: "teacher",
        });
        setEventGTM({
          event: OB_PERSONAL_INFO,
          type: "form",
          successful: true,
          failed_reason: "",
        });
        return goToNextStep();
      }
    }
    onComplete(data, actions, state);
    setEventGTM({
      event: OB_ACCOUNT_TYPE,
      type: "student",
    });
  };

  return (
    <>
      <LoginSuccessModal show={showModalLoginSuccess} />
      <div className="container">
        <h1 className="title">Thông tin cá nhân</h1>

        <div className="box-login">
          <h4 className="flex-center">
            <span>Hoặc đăng nhập bằng các cách sau đây</span>
          </h4>
          <div className={`${styles.loginList} flex-center`}>
            {/*{!isOpenTraining && (*/}
            {/*  <FacebookLogin*/}
            {/*    appId={402736724816484}*/}
            {/*    autoLoad={false}*/}
            {/*    fields="name,email,picture"*/}
            {/*    callback={responseFace}*/}
            {/*    cssClass="my-facebook-button-class"*/}
            {/*    icon="fa-facebook"*/}
            {/*    render={(renderProps) => (*/}
            {/*      <a*/}
            {/*        href="javascript:;"*/}
            {/*        className={`${styles.facebook} flex-center txt-4`}*/}
            {/*        onClick={renderProps.onClick}*/}
            {/*      >*/}
            {/*        <i className="icon icon-facebook"></i>Facebook*/}
            {/*      </a>*/}
            {/*    )}*/}
            {/*  />*/}
            {/*)}*/}
            <GoogleLogin
              clientId="794463747839-s2c9mvrkreignl677mvcibvgikto4v09.apps.googleusercontent.com"
              onSuccess={responseGoogle}
              render={(renderProps) => (
                <Link
                  to={""}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  // className="flex-center google txt-4"
                  className={`${styles.google} flex-center txt-4`}
                >
                  <i className="icon icon-gg"></i>Google
                </Link>
              )}
            />
          </div>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="form-h10 mw-535"
          id="step2"
          autoComplete="off"
        >
          {isOpenTraining && checkJob?.job_id === "1" && (
            <p className="text-left monkey-fz-20 pb-4">
              Kết quả tập huấn sẽ được gửi về Sở và EMAIL GIÁO VIÊN ! Thầy cô
              vui lòng cập nhật thông tin chính xác và đầy đủ.
            </p>
          )}
          <div className="form-group">
            <label>
              Họ và tên <sup>*</sup>
            </label>
            <input
              type="text"
              name="full_name"
              placeholder="Nhập họ và tên"
              ref={register}
              className={classNames("form-control", {
                "is-invalid": errors.full_name,
              })}
            />
            <div className="invalid-feedback">{errors.full_name?.message}</div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>
                Email <sup>*</sup>
              </label>
              <input
                type="text"
                name="email"
                placeholder="Nhập email"
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Email không hợp lệ. Vui lòng nhập đúng định dạng (ví dụ: yourname@example.com).",
                  },
                })}
                className={classNames("form-control", {
                  "is-invalid": errors.email,
                })}
              />
              <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
            <div className="form-group">
              <label>
                Số điện thoại <sup>*</sup>
              </label>
              <input
                type="text"
                name="phone"
                placeholder="Nhập số điện thoại"
                ref={register}
                className={classNames("form-control", {
                  "is-invalid": errors.phone,
                })}
              />
              <div className="invalid-feedback">{errors.phone?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <label>
              Mật khẩu <sup>*</sup>
            </label>
            <div className="input-group mb-3">
              <input
                type={isPassword ? "text" : "password"}
                name="password"
                placeholder="Nhập mật khẩu"
                ref={register}
                className={classNames("form-control", {
                  "is-invalid": errors.password,
                })}
              />
              <IStyle
                onClick={() => setStatePassword(!isPassword)}
                className={`fa cursor ${
                  isPassword ? "fa-eye monkey-bc-black" : "fa-eye-slash"
                }`}
                aria-hidden="true"
              />
              <div className="invalid-feedback">{errors.password?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <label>
              Xác nhận mật khẩu <sup>*</sup>
            </label>
            <div className="input-group mb-3">
              <input
                type={isPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder="Nhập lại mật khẩu"
                ref={register}
                className={classNames("form-control", {
                  "is-invalid": errors.confirmPassword,
                })}
              />
              <IStyle
                onClick={() => setStatePassword(!isPassword)}
                className={`fa cursor ${
                  isPassword ? "fa-eye monkey-bc-black" : "fa-eye-slash"
                }`}
                aria-hidden="true"
              />
              <div className="invalid-feedback">
                {errors.confirmPassword?.message}
              </div>
            </div>
          </div>
          <div className="box-btn flex mt-40">
            <button
              type="button"
              className="btn-back flex-center txt-4"
              onClick={goToPrevStep}
            >
              Quay lại
            </button>
            <button type="submit" className="btn-next btn-pr flex-center txt-4">
              {totalStep === 2 ? "Hoàn thành" : "Tiếp theo"}
            </button>
          </div>
        </form>
      </div>
      <RegisterSuccessModal show={show} checkJob={checkJob} />
    </>
  );
}

const IStyle = styled.i`
  position: absolute;
  z-index: 5;
  right: 30px;
  top: 16px;
  font-size: 15px;
`;
