import React from "react";

export default function TermDetails () {
	return (
		<article>
			<h4>1. Chấp thuận các Điều kiện sử dụng:</h4>
			<p>
				Hoc10.vn là một website cung cấp dịch vụ cho phép khách hàng truy cập để sử dụng sách giáo khoa điện tử Cánh Diều và tham khảo hoặc tải các hình ảnh của bộ sách phục vụ mục đích nghiên
				cứu, giảng dạy (đối với giáo viên) và học tập (đối với học sinh). Để sử dụng các tính năng này, khách hàng có thể làm theo hướng dẫn tại mục "Hướng dẫn kích hoạt".
			</p>
			<p>
                Khi sử dụng website Hoc10.vn, bạn mặc nhiên chấp thuận các điều khoản và điều kiện sử dụng dưới đây. Để cập nhật những thay đổi mới nhất, bạn nên thường xuyên kiểm tra lại mục "Điều kiện sử dụng". Hoc10.vn có quyền thay đổi, điều chỉnh, bổ sung nội dung của "Điều kiện sử dụng" bất kỳ lúc nào. Việc bạn tiếp tục sử dụng website sau các thay đổi này đồng nghĩa với việc bạn đã đồng ý với những thay đổi đó.
			</p>
			<h4>2. Tính chất hiển thị thông tin</h4>
			<p>
                Quyền sở hữu: Trang web Hoc10.vn thuộc sở hữu hợp pháp của CÔNG TY CỔ PHẦN ONE PERCENT.
			</p>
			<p>
                Thỏa thuận tự do: Khách hàng tham gia kích hoạt sách tại website Hoc10.vn trên cơ sở tự do thỏa thuận, tôn trọng quyền và lợi ích hợp pháp của các bên tham gia, phù hợp với quy chế và quy định pháp luật.
			</p>
			<p>
                Minh bạch: Hoạt động kích hoạt sách tại website Hoc10.vn phải được thực hiện công khai, minh bạch, đảm bảo quyền lợi của người sử dụng dịch vụ.
			</p>
			<p>
                Tuân thủ pháp luật: Các nội dung và quy định tại website đều phải phù hợp với hệ thống pháp luật hiện hành của Việt Nam.
			</p>
			<h4>3. Quy định về quyền lợi và nghĩa vụ của khách hàng</h4>
			<h5>
                3.1. Quy định về tài khoản và bảo mật thông tin
			</h5>
			<p>
				<strong>3.1.1. Quy định về tài khoản</strong>
			</p>
			<p>
                Khi sử dụng dịch vụ của Hoc10.vn, bạn cần cung cấp thông tin cá nhân (email, mật khẩu, họ tên, số điện thoại...) để tạo tài khoản và kích hoạt quyền sử dụng sách. Việc bảo mật và sử dụng thông tin tài khoản là trách nhiệm của bạn.
			</p>
			<p>
                Trong trường hợp thông tin cung cấp không đầy đủ hoặc sai lệch, Hoc10.vn có quyền từ chối cung cấp dịch vụ mà không chịu trách nhiệm.
			</p>
            <p>
                Bạn có thể cập nhật thông tin cá nhân của mình bằng cách đăng nhập và chỉnh sửa tại mục "Cập nhật thông tin".
			</p>
            <p>
                Đảm bảo đăng xuất tài khoản sau khi sử dụng và nhanh chóng thông báo nếu phát hiện truy cập trái phép. Trong trường hợp nghi ngờ tài khoản của mình bị truy cập trái phép, Bạn hàng phải nhanh chóng thông báo cho chúng tôi để ngăn chặn kịp thời. Chúng tôi không chịu bất kỳ trách nhiệm nào, dù trực tiếp hay gián tiếp, đối với những thiệt hại hoặc mất mát gây ra do Bạn không tuân thủ quy định.
			</p>
			<p>
				<strong>3.1.2. Quyền lợi bảo mật thông tin</strong>
			</p>
			<p>
                Hoc10.vn cam kết sử dụng thông tin cá nhân để nâng cao chất lượng dịch vụ và không chia sẻ thông tin với bên thứ ba, trừ trường hợp pháp luật yêu cầu.
			</p>
            <p>
                Các thông tin cá nhân của bạn sẽ được bảo mật tuyệt đối theo "Chính sách bảo mật".
			</p>
			<h4>
                3.2. Quyền và nghĩa vụ của người dùng
			</h4>
			<p>Quyền lợi:</p>
			<p>a. Được truy cập, sử dụng các tính năng kích hoạt sách.</p>
			<p>b. Nhận sự hỗ trợ từ đội ngũ hỗ trợ của Hoc10.vn.</p>
			<p>c. Khiếu nại nếu có sai sót trong quá trình sử dụng dịch vụ.</p>
			
			<p>Nghĩa vụ:</p>
			<p>
                a. Cung cấp thông tin chính xác và chịu trách nhiệm về các thông tin này. Ban quản lý website không chịu trách nhiệm cũng như không giải quyết mọi khiếu nại có liên quan đến quyền lợi của thành viên đó nếu xét thấy tất cả thông tin cá nhân của thành viên đó cung cấp khi đăng ký ban đầu là không chính xác.
			</p>
			<p>
				b. Không can thiệp trái phép vào hệ thống hoặc dữ liệu của website. Nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can thiệp, phá hoại hay xâm của hệ thống website. Mọi vi phạm sẽ bị xử lý theo Quy chế và quy định của pháp luật.
			</p>
			<p>
                c. Người dùng sẽ tự chịu trách nhiệm về bảo mật và lưu giữ và mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu của mình. Người dùng có thể sửa dữ liệu cá nhân của mình trên hệ thống Website. Thành viên có trách nhiệm thông báo kịp thời cho Website về những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của mình để hai bên cùng hợp tác xử lý.
			</p>
			<p>
                d. Người dùng cam kết những thông tin cung cấp cho Website và những thông tin đang tải lên Website là chính xác và hoàn chỉnh (đặc biệt thông tin có liên quan đến cá nhân của thành viên). Thành viên đồng ý giữ và thay đổi các thông tin trên Website là cập nhật, chính xác và hoàn chỉnh.
			</p>
			<p>
                e. Người dùng cam kết, đồng ý không sử dụng dịch vụ của Website vào những mục đích bất hợp pháp, không hợp lý, lừa đảo, đe doạ, thăm dò thông tin bất hợp pháp, phá hoại, tạo ra và phát tán virus gây hư hại tới hệ thống, cấu hình, truyền tải thông tin của Website hay sử dụng dịch vụ của mình vào mục đích phá hoại, tạo những cuộc hẹn giả nhằm mục đích xấu. Trong trường hợp vi phạm thì thành viên phải chịu trách nhiệm về các hành vi của mình trước pháp luật.
			</p>
			<p>
                f. Người dùng cam kết không được thay đổi, chỉnh sửa, gán ghép, copy, truyền bá, phân phối, cung cấp và tạo những công cụ tương tự của dịch vụ do Website cung cấp cho một bên thứ ba nếu không được sự đồng ý của Website trong bản Điều khoản này.
			</p>
			<p>
                g. Người dùng không được hành động gây mất uy tín của Website dưới mọi hình thức như gây mất đoàn kết giữa các thành viên bằng cách sử dụng tên đăng ký thứ hai, thông qua một bên thứ ba hoặc tuyên truyền, phổ biến những thông tin không có lợi cho uy tín của Website.
			</p>
			<h4>3.3. Quyền và nghĩa vụ của Hoc10.vn</h4>
			<p>Quyền:</p>
			<p>
                - Thay đổi nội dung, chính sách và điều khoản sử dụng sau khi thông báo trước 7 ngày.
			</p>
			<p>
                - Từ chối cung cấp dịch vụ nếu khách hàng vi phạm điều khoản.
			</p>
			<p>Nghĩa vụ:</p>
			<p>- Đảm bảo website hoạt động ổn định và phù hợp pháp luật.</p>
			<p>
                - Cập nhật thông tin đầy đủ và kịp thời.
			</p>
			<p>
                - Đảm bảo thông tin đăng tải trên website phù hợp với quy định pháp luật, thuần phong mỹ tục; đảm bảo các dịch vụ không vi phạm các quy định của pháp luật.
			</p>
			<p>
                - Có các quy định, hướng dẫn chi tiết cho người dùng về cách thức tham gia khóa học.
			</p>
            <p>
                - Hỗ trợ khách hàng trong quá trình sử dụng dịch vụ.
            </p>
			<h4>4. Quy định về miễn trừ trách nhiệm và quyền sở hữu trí tuệ</h4>
			<h5>4.1. Miễn trừ trách nhiệm</h5>
			<p>
                Hoc10.vn không đảm bảo rằng website sẽ không có lỗi vận hành, gián đoạn hoặc sai sót trong thông tin hiển thị.
			</p>
			<p>
                Hoc10.vn không chịu trách nhiệm về bất kỳ thiệt hại nào phát sinh do việc sử dụng dịch vụ.
            </p>
			<h5> 4.2. Quyền sở hữu trí tuệ</h5>
			<p>
                Nội dung tại Hoc10.vn thuộc quyền sở hữu và tài sản độc quyền khai thác của CÔNG TY CỔ PHẦN ONE PERCENT.
			</p>
			<p>Nghiêm cấm sao chép, phát tán hoặc sử dụng nội dung mà không có sự đồng ý từ Hoc10.vn.</p>
			<h4>5. Điều chỉnh và sửa đổi</h4>
			<p>
                Hoc10.vn bảo lưu quyền thay đổi, chỉnh sửa hoặc chấm dứt hoạt động của website vào bất kỳ thời điểm nào.
			</p>
			<h4>6. Giải quyết khiếu nại và tranh chấp</h4>
			<p>
                - Tranh chấp giữa website với người dùng trong quá trình thực hiện hợp đồng phải được giải quyết trên cơ sở các điều khoản của hợp đồng công bố tại website vào thời điểm giao kết hợp đồng và quy định của pháp luật có liên quan.
			</p>
			<p>
				- Việc giải quyết tranh chấp phải thông qua thương lượng giữa các bên,
				hòa giải, trọng tài hoặc tòa án theo các thủ tục, quy định hiện hành về
				giải quyết tranh chấp.
			</p>
			<p>- Việc giải quyết tranh chấp phải thông qua thương lượng giữa các bên, hòa giải, trọng tài hoặc tòa án theo các thủ tục, quy định hiện hành về giải quyết tranh chấp.</p>
			<p>
                - Giải quyết khiếu nại, tranh chấp trên website:
			</p>
			<p>
                Người dùng cần phải thường xuyên đọc và tuân theo các Chính sách và Quy định đang được đăng trên web để có thể hiểu và thực hiện được các Chính sách và Quy định tại thời điểm đó.
			</p>
			<p>
                Trong quá trình kích hoạt sách, nếu có bất kì sự cố nào trong quá trình sử dụng dịch vụ, xác nhận thông tin thanh toán. Người dùng sẽ liên hệ trực tiếp tới bộ phận hỗ trợ người dùng để được giải quyết một cách nhanh chóng và kịp thời.
			</p>
			<p>
                - Khi có bất kỳ khiếu nại nào của người dùng, vui lòng thông báo cho website. Website sẽ tiến hành xác minh và giải quyết các khiếu nại trong thời gian 07 ngày.
			</p>
			<p>
                Các bên phải thực hiện đúng trách nhiệm, quyền và nghĩa vụ của mình trong quá trình giải quyết tranh chấp. Nếu có thiệt hại xảy ra, các bên sẽ thỏa thuận cùng nhau trên tinh thần hợp tác để tìm ra biện pháp giải quyết hợp lý nhất. Nếu không đi đến được một sự thống nhất chung về ý chí trong quá trình giải quyết tranh chấp, thì sẽ đưa vụ việc tranh chấp ra cơ quan có thẩm quyền giải quyết.
			</p>
			<h4>Mọi thông tin cần thiết vui lòng liên hệ:</h4>
			<p>CÔNG TY CỔ PHẦN ONE PERCENT</p>
			<p>
				Địa chỉ: Tầng 2, tòa nhà HH-N01, dự án Gold Season, Số 47 đường Nguyễn
				Tuân, Phường Thanh Xuân Trung, Quận Thanh Xuân, Tp. Hà Nội
			</p>
			<p>
				Mã số thuế: 0109621809 do Sở Kế hoạch và Đầu tư TP Hà Nội, cấp ngày
				06/05/2021
			</p>
			
			<p>Người chịu trách nhiệm chính: Ông Đào Xuân Hoàng</p>
			
			<p>
				Hotline: <a href="tel:02473098866">02473098866</a>
			</p>
			
			<p>
				Email: <a href="mailto:contact@hoc10.vn">contact@hoc10.vn</a>
			</p>
			
			<p>Xin chân thành cảm ơn!</p>
		</article>
	);
}
