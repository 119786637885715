import React from "react";

export default function PrivacyDetails () {
	return (
		<article>
			<p>
				Bảo mật thông tin của khách hàng là một trong những ưu tiên hàng đầu tại Hoc10.vn. Chúng tôi cam kết sử dụng thông tin của khách hàng một cách hợp lý, có cân nhắc và tuân thủ quy định
				pháp luật nhằm nâng cao chất lượng dịch vụ chăm sóc khách hàng.
			</p>
			<h2>1. Mục đích và phạm vi thu thập thông tin</h2>
			<p>
				Để truy cập và sử dụng một số dịch vụ tại Hoc10.vn, bạn có thể được yêu cầu cung cấp thông tin cá nhân (như email, họ tên, số điện thoại liên lạc). Thông tin khai báo phải đảm bảo tính
				chính xác và hợp pháp. Hoc10.vn không chịu trách nhiệm pháp lý liên quan đến thông tin khai báo không đúng sự thật.
			</p>
			<p>
				Chúng tôi cũng có thể thu thập thông tin về số lần truy cập, số trang bạn xem, số liên kết bạn nhấp và các thông tin khác liên quan đến việc kết nối với website. Thông tin trình duyệt
				(địa chỉ IP, loại trình duyệt, ngôn ngữ sử dụng, thời gian truy cập) cũng được ghi nhận nhằm cải thiện trải nghiệm người dùng.
			</p>
			<p>
				Thông tin cá nhân thu thập được sẽ chỉ được sử dụng cho các mục đích sau:
			</p>
			<p> Hỗ trợ khách hàng.</p>
			<p> Cung cấp thông tin về dịch vụ.</p>
			<p>
				 Xử lý đơn đặt hàng và cung cấp dịch vụ theo yêu cầu của bạn.
			</p>
			<p>
				 Gửi thông tin sản phẩm, dịch vụ mới, sự kiện hoặc thông báo tuyển dụng (nếu bạn đăng ký nhận email thông báo).
			</p>
			<p>
				 Hỗ trợ quản lý tài khoản khách hàng, xác nhận và thực hiện giao dịch tài chính liên quan đến thanh toán trực tuyến.
			</p>
			<h2>2 - Phạm vi sử dụng thông tin</h2>
			<p>
                Hoc10.vn sử dụng thông tin cá nhân của khách hàng theo đúng mục đích đã được cam kết trong chính sách này. Khi cần thiết, chúng tôi có thể liên hệ trực tiếp với bạn thông qua email, điện thoại, tin nhắn hoặc các hình thức khác để cung cấp thông tin dịch vụ, hỗ trợ kỹ thuật, hoặc thông báo quan trọng.
			</p>
			<h2>3 - Thời gian lưu trữ thông tin</h2>
			<p>
                Thông tin cá nhân của khách hàng sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ hoặc khi khách hàng tự đăng nhập và thực hiện hủy bỏ trên hệ thống. Trong mọi trường hợp, thông tin sẽ được bảo mật trên máy chủ của Hoc10.vn.
			</p>
			<h2>4 - Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân</h2>
			<p>CÔNG TY CỔ PHẦN ONE PERCENT</p>
			<p>
                Địa chỉ: Tầng 2, tòa nhà HH-N01, dự án Gold Season, Số 47 đường Nguyễn Tuân, Phường Thanh Xuân Trung, Quận Thanh Xuân, Tp. Hà Nội.
			</p>
			<p>
				Hotline: <a href="tel:02473098866">02473098866</a>
			</p>
			<p>
				Email: <a href="mailto:contact@hoc10.vn">contact@hoc10.vn</a>
			</p>
			<h2>
				5 - Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân
			</h2>
			<p>
                Khách hàng có thể tự chỉnh sửa thông tin cá nhân (bao gồm thông tin cơ bản, thông tin trường học, thông tin giảng dạy và liên lạc) bằng cách truy cập trang "Cập nhật thông tin" sau khi đăng nhập thành công vào hệ thống Hoc10.vn.
			</p>
			<h2>6 - Cam kết bảo mật thông tin cá nhân khách hàng</h2>
			<p>
                Hoc10.vn cam kết bảo mật thông tin cá nhân của khách hàng tuyệt đối theo chính sách bảo mật. Việc thu thập và sử dụng thông tin chỉ được thực hiện khi có sự đồng ý của khách hàng, ngoại trừ các trường hợp luật pháp yêu cầu khác.
			</p>
			<p>
                Không chuyển giao, cung cấp hoặc tiết lộ thông tin cá nhân cho bên thứ ba nếu không có sự đồng ý từ khách hàng, trừ trường hợp có yêu cầu từ cơ quan chức năng.
			</p>
			<p>
                Trong trường hợp máy chủ bị tấn công, dẫn đến mất dữ liệu cá nhân, chúng tôi sẽ thông báo đến cơ quan chức năng và khách hàng kịp thời.
			</p>
			<p>
                Bảo mật tuyệt đối thông tin giao dịch trực tuyến của khách hàng tại trung tâm dữ liệu an toàn cấp cao.
			</p>
			<p>
                Chúng tôi khuyến nghị khách hàng tự bảo vệ thông tin tài khoản và mật khẩu, đặc biệt khi sử dụng máy tính chung. Đảm bảo đăng xuất sau khi sử dụng dịch vụ.
			</p>
			<p>
                Do giới hạn kỹ thuật, không có dữ liệu nào được truyền trên internet đảm bảo an toàn tuyệt đối.  Vì vậy, chúng tôi không thể đưa ra một cam kết chắc chắn rằng thông tin quý khách cung cấp cho chúng tôi được bảo mật một cách tuyệt đối an toàn, và chúng tôi không chịu trách nhiệm trong trường hợp có sự truy cập trái phép nếu khách hàng tự ý chia sẻ thông tin cá nhân với người khác.
			</p>
			<h2>Mọi thông tin cần thiết vui lòng liên hệ:</h2>
			<p>CÔNG TY CỔ PHẦN ONE PERCENT</p>
			<p>
                Địa chỉ: Tầng 2, tòa nhà HH-N01, dự án Gold Season, Số 47 đường Nguyễn Tuân, Phường Thanh Xuân Trung, Quận Thanh Xuân, Tp. Hà Nội
            </p>
			<p>
                Mã số thuế: 0109621809 do Sở Kế hoạch và Đầu tư TP Hà Nội, cấp ngày 06/05/2021
            </p>
			
			<p>Người chịu trách nhiệm chính: Ông Đào Xuân Hoàng</p>
			
			<p>
				Hotline: <a href="tel:02473098866">02473098866</a>
			</p>
			
			<p>
				Email: <a href="mailto:contact@hoc10.vn">contact@hoc10.vn</a>
			</p>
			
			<p>Xin chân thành cảm ơn!</p>
		</article>
	);
}
