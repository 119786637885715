import React, { Fragment, useState, useRef } from "react";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import Select from "react-select";
import Support from "./PopupSupport.module.scss";

const PopupSupport = () => {
  const data = [
    {
      value: 0,
      label: "Hướng dẫn sử dụng Sách Cánh Diều",
      path: "https://youtu.be/d2L8c06oU2Y",
    },
    {
      value: 1,
      label: "Bài tập nối theo cặp",
      path: "https://youtu.be/IKO5i_q4wSo",
    },
    {
      value: 2,
      label: "Bài tập nối dạng điểm ảnh",
      path: "https://youtu.be/7p1uogS4DtI",
    },
    {
      value: 3,
      label: "Bài tập kéo thả dạng 1",
      path: "https://youtu.be/twfE65DHuRw",
    },
    {
      value: 4,
      label: "Bài tập kéo thả dạng 2",
      path: "https://youtu.be/0RYkULrKxpQ",
    },
    {
      value: 5,
      label: "Bài tập tìm từ",
      path: "https://youtu.be/w5fEBlohUZw",
    },
    {
      value: 6,
      label: "Bài tập tìm câu",
      path: "https://youtu.be/DVAQxQOeiIc",
    },
    {
      value: 7,
      label: "Bài tập tìm từ sai và sửa",
      path: "https://youtu.be/TMhGWPf5R_s",
    },
    {
      value: 8,
      label: "Bài tập sắp xếp theo thứ tự đúng",
      path: "https://youtu.be/dvezSDDEXes",
    },
    {
      value: 9,
      label: "Bài tập trình chiếu hình ảnh/audio/video",
      path: "https://youtu.be/1h3_7zYCjSE",
    },
    {
      value: 10,
      label: "Bài tập show đáp án, gợi ý",
      path: "https://youtu.be/P6vPbrm1a-w",
    },
    {
      value: 11,
      label: "Bài tập ô chữ",
      path: "https://youtu.be/RYfqZjPvMiQ",
    },
    {
      value: 12,
      label: "Bài tập chạy chữ synctext",
      path: "https://youtu.be/5hCq8Y9my4U",
    },
    {
      value: 13,
      label: "Bài tập tô màu",
      path: "https://youtu.be/JktB7FxCl-8",
    },
    {
      value: 14,
      label: "Bài tập điền vào chỗ trống",
      path: "https://youtu.be/_doQNuAXSFY",
    },
    {
      value: 15,
      label: "Bài tập điền có hỗ trợ bàn phím ảo",
      path: "https://youtu.be/osfD-al8QEY",
    },
    {
      value: 16,
      label: "Bài tập điền vào chỗ trống - dạng chọn đáp án dropdown",
      path: "https://youtu.be/U9n6ab2KKm4",
    },
    {
      value: 17,
      label: "Bài tập chọn 1 câu trả lời đúng",
      path: "https://youtu.be/_H3lRUu0ZHk",
    },
    {
      value: 18,
      label: "Bài tập chọn nhiều câu trả lời đúng",
      path: "https://youtu.be/e4olPEOZLKc",
    },
    {
      value: 19,
      label: "Bài tập chọn đáp án đúng dạng điểm ảnh",
      path: "https://youtu.be/EREjekRC--0",
    },
    {
      value: 20,
      label: "Bài tập đồ thị",
      path: "https://youtu.be/8m2ykihiJlE",
    },
    {
      value: 21,
      label: "Bài tập miền nghiệm",
      path: "https://youtu.be/JR7jXbjuPtI",
    },
    {
      value: 22,
      label: "Xem link tham khảo",
      path: "https://youtu.be/pBUlICnNEC4",
    },
  ];
  const [valueSelected, setValueSelected] = useState(data[0]);

  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      justifyContent: "center",
    }),
    placeholder: () => ({
      margin: "auto",
    }),
    control: (base) => ({
      ...base,
      height: 44,
      width: 450,
      textAlign: "center",
      borderRadius: 30,
      zIndex: 500,
      backgroundColor: "#fff",
    }),
  };
  const handleChange = (selectedOption) => {
    setValueSelected(selectedOption);
    sliderRef.current.slickGoTo(selectedOption.value);
  };
  const sliderRef = useRef();
  const settings = {
    // dots: false,
    infinite: false,
    speed: 500,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="d-flex justify-content-center pt-3 pb-2">
        <Select
          value={valueSelected}
          onChange={handleChange}
          options={data}
          styles={customStyles}
          isSearchable={true}
        />
      </div>
      <div>
        <Slider className={Support.slickList} ref={sliderRef} {...settings}>
          {data.map((item, index) => (
            <ReactPlayer
              width="100%"
              key={index}
              pip={true}
              controls={true}
              url={item.path}
            />
          ))}
        </Slider>
      </div>
    </>
  );
};
export default PopupSupport;
