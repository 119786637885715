import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import error_img from "edu_lms_v2/assets/img/img-error.svg";

export default function PopupContent(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success modal-login-success"
      animation={true}
      backdrop={true}
    >
      <div className="box-success p-0">
        <div className="img-wrapper">
          <img src={error_img} alt="Nội dung" />
        </div>
        <Message className="text">
          {props.message.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </Message>
        <Link
          title="Truy cập tủ sách"
          className="btn-pr flex-center btn-access cursor"
          to={{
            pathname: props.url,
            state: { from: props.urlRedirect ? props.urlRedirect : `${PATH.ROUTE_PATH_V3_LIST_SLIDE}tieng-viet/lop-1`},
          }}
        >
          {props.labelUrl}
        </Link>
      </div>
    </Modal>
  );
}

const Message = styled.div`
  font-size: 22px !important;
`;
