import { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ROUTE_PATH_V2_READING_SLIDE } from "consts/routePaths";
import { encryptBase64 } from "edu_lms/modules/selection";
import { postUpdateSlideLibrary } from "edu_lms/services/slide";
import PopupContent from "./PopupContent";
import * as PATH from "consts/routePaths";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { OPEN_LECTURE } from "edu_lms/constants/eventNameGTM";
import StyleSlide from "./Slide.module.scss";

const buildUrl = ({ slideId, subjectSlug, gradeSlug, slideSlug }) => {
  const hashId = encryptBase64(slideId);
  const url = `${ROUTE_PATH_V2_READING_SLIDE}/${subjectSlug}/${gradeSlug}/${slideSlug}/${hashId}/`;

  return url;
};

export default function Slide(slide) {
  const history = useHistory();
  const [showPopupContent, setShowPopupContent] = useState(false);
  const [urlSlide, setUrlSlide] = useState('');

  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const token = localStorage.getItem("token");

  const onReadingSlideLibrary = async (detailSlide) => {
    const url = buildUrl({
      slideId: detailSlide?.id,
      subjectSlug: detailSlide?.subject?.slug,
      gradeSlug: detailSlide?.grade?.slug,
      slideSlug: detailSlide?.slug,
    });
    if (!token) {
      setUrlSlide(url);
      setShowPopupContent(true);
      return;
    }

    if (currentTimeInSeconds <= detailSlide.published_date) {
      return;
    }

    try {
      const data = {
        slideId: detailSlide.id,
        typeUpdate: 1,
      };
      await postUpdateSlideLibrary(data);

      history.push(url);
    } catch (error) {
      console.error(error);
    }

    // get event reading lecture
    setEventGTM({
      event: OPEN_LECTURE,
      lecture_name: detailSlide.title,
      lecture_grade: detailSlide.grade_name,
      lecture_subject: detailSlide.subject_name,
      count_share: detailSlide.count_share,
      count_present: detailSlide.count_view,
      count_download: detailSlide.count_download,
    });
  };

  const handleViewSlide = () => {
    onReadingSlideLibrary(slide.slide);
  };

  return (
    <div className="col-sm-12 col-lg-4">
      <Article className="post">
        <a href="javascript:;" title="" className="post__thumb">
          <img
            src={`${process.env.REACT_APP_MEDIA_URL_APP_COMMON}${slide.slide.thumb}`}
            alt={slide.slide.title}
            loading="lazy"
            onClick={handleViewSlide}
          />
        </a>
        <div className="post__content">
          <h3>
            <a
              className={StyleSlide.text_hidden}
              href="javascript:;"
              title={slide.slide.title}
              onClick={handleViewSlide}
            >
              {slide.slide.title}
            </a>
          </h3>
          <div className="tag flex">
            <a href="javascript:;" title="">
              {slide.slide.subject_name}
            </a>
            <a href="javascript:;" title="">
              {slide.slide.grade_name}
            </a>
          </div>
          <span
            title=""
            className="btn-view btn-pr flex-center cursor"
            onClick={handleViewSlide}
          >
            Xem nhanh
          </span>
        </div>
        <div className="data flex-lc">
          <div className="download flex-lc">
            <i className="icon icon-download"></i> {slide.slide.count_download}
          </div>
          <div className="view flex-lc">
            <i className="icon icon-view"></i> {slide.slide.count_view}
          </div>
          <div className="share flex-lc">
            <i className="icon icon-share"></i> {slide.slide.count_share}
          </div>
        </div>
        {slide.slide.is_week_current === 0 &&
          currentTimeInSeconds < slide.slide.published_date && (
            <div class="block flex-center">
              <i class="icon icon-block"></i>
              <p>
                {" "}
                Ngày phát hành:{" "}
                {moment(slide.slide.published_date * 1000).format("DD/MM/YYYY")}
              </p>
            </div>
          )}

        {slide.slide.is_week_current === 0 &&
          currentTimeInSeconds > slide.slide.published_date && (
            <div class="block flex-center">
              <i class="icon icon-block"></i>
            </div>
          )}
      </Article>
      <PopupContent
        show={showPopupContent}
        message={["Vui lòng đăng nhập để xem", "Góc chia sẻ bài giảng"]}
        labelUrl={"Đăng nhập"}
        url={PATH.ROUTE_PATH_V3_SIGN_IN}
        urlRedirect={urlSlide}
        onHide={() => setShowPopupContent(false)}
      />
    </div>
  );
}

const Article = styled.article`
  & .block {
    display: none;
  }

  &:hover .block {
    display: flex;
  }
`;
